exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-produtos-alcool-index-tsx": () => import("./../../../src/pages/produtos/alcool/index.tsx" /* webpackChunkName: "component---src-pages-produtos-alcool-index-tsx" */),
  "component---src-pages-produtos-aromatizantes-e-essencias-index-tsx": () => import("./../../../src/pages/produtos/aromatizantes-e-essencias/index.tsx" /* webpackChunkName: "component---src-pages-produtos-aromatizantes-e-essencias-index-tsx" */),
  "component---src-pages-produtos-baldes-index-tsx": () => import("./../../../src/pages/produtos/baldes/index.tsx" /* webpackChunkName: "component---src-pages-produtos-baldes-index-tsx" */),
  "component---src-pages-produtos-carros-funcionais-index-tsx": () => import("./../../../src/pages/produtos/carros-funcionais/index.tsx" /* webpackChunkName: "component---src-pages-produtos-carros-funcionais-index-tsx" */),
  "component---src-pages-produtos-ceras-e-removedores-index-tsx": () => import("./../../../src/pages/produtos/ceras-e-removedores/index.tsx" /* webpackChunkName: "component---src-pages-produtos-ceras-e-removedores-index-tsx" */),
  "component---src-pages-produtos-descartaveis-index-tsx": () => import("./../../../src/pages/produtos/descartaveis/index.tsx" /* webpackChunkName: "component---src-pages-produtos-descartaveis-index-tsx" */),
  "component---src-pages-produtos-desengordurantes-index-tsx": () => import("./../../../src/pages/produtos/desengordurantes/index.tsx" /* webpackChunkName: "component---src-pages-produtos-desengordurantes-index-tsx" */),
  "component---src-pages-produtos-desengraxantes-index-tsx": () => import("./../../../src/pages/produtos/desengraxantes/index.tsx" /* webpackChunkName: "component---src-pages-produtos-desengraxantes-index-tsx" */),
  "component---src-pages-produtos-desinfetantes-e-higienizadores-index-tsx": () => import("./../../../src/pages/produtos/desinfetantes-e-higienizadores/index.tsx" /* webpackChunkName: "component---src-pages-produtos-desinfetantes-e-higienizadores-index-tsx" */),
  "component---src-pages-produtos-detergentes-index-tsx": () => import("./../../../src/pages/produtos/detergentes/index.tsx" /* webpackChunkName: "component---src-pages-produtos-detergentes-index-tsx" */),
  "component---src-pages-produtos-dispensers-index-tsx": () => import("./../../../src/pages/produtos/dispensers/index.tsx" /* webpackChunkName: "component---src-pages-produtos-dispensers-index-tsx" */),
  "component---src-pages-produtos-epi-index-tsx": () => import("./../../../src/pages/produtos/epi/index.tsx" /* webpackChunkName: "component---src-pages-produtos-epi-index-tsx" */),
  "component---src-pages-produtos-escovas-index-tsx": () => import("./../../../src/pages/produtos/escovas/index.tsx" /* webpackChunkName: "component---src-pages-produtos-escovas-index-tsx" */),
  "component---src-pages-produtos-esponjas-index-tsx": () => import("./../../../src/pages/produtos/esponjas/index.tsx" /* webpackChunkName: "component---src-pages-produtos-esponjas-index-tsx" */),
  "component---src-pages-produtos-hospitalar-index-tsx": () => import("./../../../src/pages/produtos/hospitalar/index.tsx" /* webpackChunkName: "component---src-pages-produtos-hospitalar-index-tsx" */),
  "component---src-pages-produtos-inseticidas-index-tsx": () => import("./../../../src/pages/produtos/inseticidas/index.tsx" /* webpackChunkName: "component---src-pages-produtos-inseticidas-index-tsx" */),
  "component---src-pages-produtos-limpadores-index-tsx": () => import("./../../../src/pages/produtos/limpadores/index.tsx" /* webpackChunkName: "component---src-pages-produtos-limpadores-index-tsx" */),
  "component---src-pages-produtos-lixeiras-e-cestos-index-tsx": () => import("./../../../src/pages/produtos/lixeiras-e-cestos/index.tsx" /* webpackChunkName: "component---src-pages-produtos-lixeiras-e-cestos-index-tsx" */),
  "component---src-pages-produtos-lustra-moveis-index-tsx": () => import("./../../../src/pages/produtos/lustra-moveis/index.tsx" /* webpackChunkName: "component---src-pages-produtos-lustra-moveis-index-tsx" */),
  "component---src-pages-produtos-luvas-index-tsx": () => import("./../../../src/pages/produtos/luvas/index.tsx" /* webpackChunkName: "component---src-pages-produtos-luvas-index-tsx" */),
  "component---src-pages-produtos-mascaras-index-tsx": () => import("./../../../src/pages/produtos/mascaras/index.tsx" /* webpackChunkName: "component---src-pages-produtos-mascaras-index-tsx" */),
  "component---src-pages-produtos-panos-e-flanelas-index-tsx": () => import("./../../../src/pages/produtos/panos-e-flanelas/index.tsx" /* webpackChunkName: "component---src-pages-produtos-panos-e-flanelas-index-tsx" */),
  "component---src-pages-produtos-papelaria-index-tsx": () => import("./../../../src/pages/produtos/papelaria/index.tsx" /* webpackChunkName: "component---src-pages-produtos-papelaria-index-tsx" */),
  "component---src-pages-produtos-placas-sinalizadoras-index-tsx": () => import("./../../../src/pages/produtos/placas-sinalizadoras/index.tsx" /* webpackChunkName: "component---src-pages-produtos-placas-sinalizadoras-index-tsx" */),
  "component---src-pages-produtos-sabao-e-sabonetes-index-tsx": () => import("./../../../src/pages/produtos/sabao-e-sabonetes/index.tsx" /* webpackChunkName: "component---src-pages-produtos-sabao-e-sabonetes-index-tsx" */),
  "component---src-pages-produtos-sacos-e-sacolas-index-tsx": () => import("./../../../src/pages/produtos/sacos-e-sacolas/index.tsx" /* webpackChunkName: "component---src-pages-produtos-sacos-e-sacolas-index-tsx" */),
  "component---src-pages-produtos-vassouras-e-rodos-index-tsx": () => import("./../../../src/pages/produtos/vassouras-e-rodos/index.tsx" /* webpackChunkName: "component---src-pages-produtos-vassouras-e-rodos-index-tsx" */),
  "component---src-pages-sobre-a-agilize-tsx": () => import("./../../../src/pages/sobre-a-agilize.tsx" /* webpackChunkName: "component---src-pages-sobre-a-agilize-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/Templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

